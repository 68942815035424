import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ApproveDialog from "../../../shared/Approve.vue";
import moment from "moment-timezone";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import { mask } from "vue-the-mask";
import Validation from "@/helpers/Validation";
import { VueEditor } from "vue2-editor";
import HandbookModule from "@/store/module/shared/HandbookModule";
import GroupModule from "@/store/module/shared/GroupModule";
import UsersModule from "@/store/module/admin/UsersModule";
import AdminModule from "@/store/module/admin/AdminModule";
import { PhoneMask } from "@/helpers/PhoneMask";
let GroupDialog = class GroupDialog extends Vue {
    constructor() {
        super(...arguments);
        this.Validation = Validation;
        this.approve = {
            add: false,
            update: false,
        };
        this.uploadedDocuments = [];
        this.pickedRegion = null;
        this.phoneCountrySelect = {
            defaultCountry: "ru",
            dialCode: "+7",
            onlyCountries: ["ru", "az", "am", "by", "kz", "md", "tj", "uz", "ua"],
            dynamicPlaceholder: true,
        };
        this.logo = {
            ref: "logo",
            type: "fileupload",
            icon: "mdi-image-filter-black-white",
            placeholder: "Загрузите логотип",
            value: "",
            file: null,
            accept: "image/*",
            onChange: async (file) => {
                if (file === null)
                    return;
                const fd = new FormData();
                fd.append("logo", file);
                const response = await this.$store.dispatch(GroupModule.types.actions.UPLOAD_AVATAR, { fd });
                this.logo.value = response.filename;
            },
            rules: [Validation.required],
            showOnlyForSuperAuth: false,
        };
        this.docs = {
            ref: "docs",
            type: "fileupload",
            icon: "mdi-file-multiple",
            placeholder: "Загрузите документы (можно несколько)",
            value: [],
            accept: "image/*,application/*",
            onChange: async (files) => {
                if (files === null || files.length === 0) {
                    this.uploadedDocuments.splice(0, this.uploadedDocuments.length);
                    return;
                }
                let arrayOfFiles = [];
                if (Array.isArray(files) === false) {
                    arrayOfFiles = [files];
                }
                else {
                    arrayOfFiles = files;
                }
                const userFiles = arrayOfFiles.map((file) => {
                    file.userName = null;
                    return file;
                });
                for (let file of userFiles) {
                    this.uploadedDocuments.push(file);
                }
            },
            rules: [Validation.required],
            showOnlyForSuperAuth: false,
        };
        this.fullName = {
            ref: "fullName",
            type: "input",
            label: "Полное название",
            value: "",
            placeholder: "Спортивный клуб «В яблочко»",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.shortName = {
            ref: "shortName",
            type: "input",
            label: "Короткое название",
            value: "",
            placeholder: "СК «В яблочко»",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        // private code: IDefaultInput = {
        //   ref: "code",
        //   type: "input",
        //   label: "Код",
        //   value: "",
        //   placeholder: "38004",
        //   showOnlyForSuperAuth: false,
        //   rules: [Validation.required],
        // }
        this.executive = {
            ref: "executivepick",
            type: "select",
            label: "Руководитель",
            value: "",
            items: () => this.users,
            onInput: () => { },
            isMultiple: false,
            isAutocomplete: true,
            noData: "Список пользователей пуст",
            disabled: false,
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.baseFields = [this.fullName, this.shortName, this.executive];
        this.email = {
            ref: "email",
            type: "input",
            label: "E-mail",
            value: "",
            showOnlyForSuperAuth: false,
            rules: [Validation.required, Validation.email],
        };
        this.website = {
            ref: "website",
            type: "input",
            label: "Веб-сайт",
            placeholder: "https://my.archery.ru",
            value: "",
            showOnlyForSuperAuth: false,
            rules: [],
        };
        this.phone = {
            ref: "phone",
            type: "phone",
            label: "Телефон",
            value: "",
            placeholder: PhoneMask.getPlaceholderByCountry("ru"),
            mask: PhoneMask.getMaskByPlaceholder("", PhoneMask.getPlaceholderByCountry("ru")),
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.contactsFields = [this.email, this.website, this.phone];
        this.region = {
            ref: "region",
            type: "select",
            label: "Регион",
            value: "",
            items: () => this.regions,
            onInput: (id) => {
                this.pickedRegion = id;
                this.city.disabled = false;
            },
            isMultiple: false,
            disabled: false,
            noData: "Список доступных регионов пуст",
            showOnlyForSuperAuth: true,
            rules: [],
        };
        this.city = {
            ref: "city",
            type: "select",
            label: "Город",
            value: "",
            items: () => this.citiesByRegion,
            onInput: () => { },
            isMultiple: false,
            noData: "Список городов для выбранного региона пуст",
            disabled: true,
            showOnlyForSuperAuth: false,
            rules: [],
        };
        this.address = {
            ref: "groupAddress",
            type: "input",
            label: "Адрес",
            value: "",
            placeholder: "Москва, ул. Семеновская, д.1",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.additionalAddresses = [];
        this.geoFields = [this.region, this.city, this.address];
        this.description = {
            type: "wysiwyg",
            value: "",
            showOnlyForSuperAuth: false,
        };
        this.showcase = {
            type: "checkbox",
            label: "Опубликовать на archery.ru",
            value: true,
            showOnlyForSuperAuth: true,
            onChange: (value) => { },
        };
        this.allRussian = {
            type: "checkbox",
            label: "Общероссийская организация",
            value: false,
            showOnlyForSuperAuth: true,
            onChange: (value) => { },
        };
        this.isRegionalFederation = {
            type: "checkbox",
            label: "Региональная федерация",
            value: false,
            showOnlyForSuperAuth: false,
            show: true,
            onChange: (value) => { },
        };
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    get users() {
        return this.$store.getters[UsersModule.types.getters.USERS_BY_CATEGORIES].any.map((user) => ({
            ...user,
            nsbp: `${user.surname} ${user.name} ${user.patronymic} - ${moment(user.birthdate).format("YYYY-MM-DD")}`,
        }));
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get citiesByRegion() {
        if (this.pickedRegion === null)
            return [];
        return this.$store.getters[HandbookModule.types.getters.CITIES].filter((item) => item.regionId === this.pickedRegion);
    }
    get regions() {
        return this.$store.getters[HandbookModule.types.getters.REGIONS];
    }
    get defaultAvatar() {
        return `${IMAGE_SERVER_URL}/public/archery-event-placeholder.jpg`;
    }
    get uploadedAvatar() {
        return `${IMAGE_SERVER_URL}/public/${this.logo.value}`;
    }
    onPhoneCountryChange(val) {
        this.phoneCountrySelect.dialCode = val.dialCode;
        this.phone.placeholder = PhoneMask.getPlaceholderByCountry(val.iso2.toLowerCase());
        this.phone.mask = PhoneMask.getMaskByPlaceholder(``, this.phone.placeholder);
        this.phone.value.replace(/\+[0-9]+/, "");
    }
    setTestFormData() {
        this.fullName.value = "Спортивный клуб 'В яблочко'";
        this.shortName.value = "СК 'В яблочко'";
        // this.code.value = "38004"
        this.website.value = "https://my.archery.ru";
        this.phone.value = "(931) 231-23-23";
        this.email.value = "wow-aka.moy@yandex.ru";
        this.region.value = 85;
        this.region.onInput(85);
        this.city.value = 795;
        this.description.value = "<b>Описание</b>";
    }
    setUpdateFormData() {
        this.fullName.value = this.group.fullName;
        this.shortName.value = this.group.shortName;
        this.website.value = this.group.contacts.website;
        const phoneSplited = this.group.contacts.phone.split(" ");
        phoneSplited.shift();
        this.phone.value = phoneSplited.join(" ");
        this.email.value = this.group.contacts.email;
        this.region.value = this.group.contacts.address.regionId;
        this.region.onInput(this.group.contacts.address.regionId);
        this.city.value = this.group.contacts.address.cityId;
        this.description.value = this.group.description;
        this.logo.value = this.group.logo;
        this.executive.value = this.group.executiveId;
        this.address.value = this.group.contacts.addressText;
        this.showcase.value = this.group.showcase;
        this.allRussian.value = this.group.isAllRussian;
        this.isRegionalFederation.value = this.group.isRegionalFederation ? true : false;
        if (this.group.additionalAddresses.length > 0) {
            let index = 0;
            for (let addr of this.group.additionalAddresses) {
                this.addAdditionalAddress();
                this.additionalAddresses[index].regionPicker.value = addr.address.regionId;
                this.additionalAddresses[index].regionPicker.onInput(addr.address.regionId);
                this.additionalAddresses[index].cityPicker.disabled = false;
                this.additionalAddresses[index].cityPicker.value = addr.address.cityId;
                this.additionalAddresses[index].address.value = addr.addressText;
                index++;
            }
        }
    }
    cleanForm() {
        this.fullName.value = "";
        this.shortName.value = "";
        // this.code.value = ""
        this.website.value = "";
        this.phone.value = "";
        this.email.value = "";
        this.region.value = "";
        this.region.onInput("");
        this.city.value = "";
        this.description.value = "";
        this.uploadedDocuments.splice(0, this.uploadedDocuments.length);
        this.logo.value = "";
        this.docs.value = [];
        this.executive.value = [];
        this.showcase.value = true;
        this.additionalAddresses.splice(0, this.additionalAddresses.length);
    }
    getCitiesByPickedRegionAdditonal(index) {
        return this.$store.getters[HandbookModule.types.getters.CITIES].filter((item) => item.regionId === this.additionalAddresses[index]?.regionPicker?.picked);
    }
    addAdditionalAddress() {
        const index = this.additionalAddresses.length;
        this.additionalAddresses.push({
            regionPicker: {
                ref: `region${index}`,
                type: "select",
                label: "Регион",
                value: "",
                items: () => this.regions,
                picked: null,
                onInput: (id) => {
                    this.additionalAddresses[index].regionPicker.picked = id;
                    this.additionalAddresses[index].cityPicker.disabled = false;
                },
                isMultiple: false,
                disabled: false,
                noData: "Список доступных регионов пуст",
                showOnlyForSuperAuth: true,
                rules: [],
            },
            cityPicker: {
                ref: `city${index}`,
                type: "select",
                label: "Город",
                value: "",
                items: () => this.getCitiesByPickedRegionAdditonal(index),
                onInput: () => { },
                isMultiple: false,
                noData: "Список городов для выбранного региона пуст",
                disabled: true,
                showOnlyForSuperAuth: false,
                rules: [],
            },
            address: {
                ref: `groupAddress${index}`,
                type: "input",
                label: "Адрес",
                value: "",
                placeholder: "Москва, ул. Семеновская, д.1",
                showOnlyForSuperAuth: false,
                rules: [],
            },
        });
    }
    removeAdditionalAddress(index) {
        this.additionalAddresses.splice(index, 1);
    }
    // async generateCode() {
    //   const region = this.regions.find(r => r.id === parseInt(String(this.region.value)))
    //   let code = `1${region.okato}`
    //   if (this.isRegionalFederation.value === true) {
    //     return `${code}001`
    //   } else {
    //   }
    // }
    async add() {
        const form = [...this.baseFields, ...this.geoFields, ...this.contactsFields];
        for (let doc of this.uploadedDocuments) {
            form.push({
                ref: doc.name,
                rules: [Validation.required],
            });
        }
        form.push(this.logo);
        form.push(this.docs);
        let hasError = Validation.validateWithView(form, this.$refs);
        if (hasError)
            return;
        const group = {
            fullName: this.fullName.value,
            shortName: this.shortName.value,
            // code: this.code.value,
            executiveId: parseInt(String(this.executive.value)),
            creatorId: this.admin.id,
            description: this.description.value,
            logo: this.logo.value,
            isPremoderated: true,
            showcase: this.showcase.value,
            isAllRussian: this.allRussian.value,
            isRegionalFederation: this.isRegionalFederation.value,
            contacts: {
                website: this.website.value,
                email: this.email.value,
                phone: `+${this.phoneCountrySelect.dialCode} ${this.phone.value}`,
                address: {
                    regionId: this.region.value === "" ? null : this.region.value,
                    cityId: this.city.value === "" ? null : this.city.value,
                },
                addressText: this.address.value,
            },
            participants: [],
            photos: [],
            documents: [],
            additionalAddresses: this.additionalAddresses.map((additional) => {
                return {
                    addressText: additional.address.value,
                    address: {
                        regionId: additional.regionPicker.value === "" ? null : additional.regionPicker.value,
                        cityId: additional.cityPicker.value === "" ? null : additional.cityPicker.value,
                    },
                };
            }),
        };
        const existedGroup = this.$store.getters[GroupModule.types.getters.GROUPS].find((item) => item.fullName === group.fullName || item.shortName === group.shortName || item.code === group.code);
        if (existedGroup !== undefined) {
            return this.$notify({
                type: "error",
                duration: 3000,
                title: "Уже существует сообщество с такими параметрами",
            });
        }
        const response = await this.$store.dispatch(GroupModule.types.actions.ADD_GROUP, { group });
        this.$notify({
            type: "success",
            title: "Сообщество успешно добавлено и отправлено на премодерацию",
        });
        const docsFd = new FormData();
        for (let doc of this.uploadedDocuments) {
            docsFd.append("docs", doc);
            docsFd.append("name", doc.userName);
        }
        docsFd.append("groupId", response.id);
        await this.$store.dispatch(GroupModule.types.actions.UPLOAD_DOCUMENTS, { fd: docsFd });
        this.$notify({
            type: "success",
            title: "Документы сообщества успешно загружены",
        });
        this.cleanForm();
    }
    async update() {
        const form = [...this.baseFields, ...this.geoFields, ...this.contactsFields];
        let hasError = Validation.validateWithView(form, this.$refs);
        if (hasError)
            return;
        const group = {
            id: this.group.id,
            fullName: this.fullName.value,
            shortName: this.shortName.value,
            //code: this.code.value,
            description: this.description.value,
            logo: this.logo.value === "" ? this.group.logo : this.logo.value,
            executiveId: parseInt(String(this.executive.value)),
            creatorId: this.group.creatorId,
            isPremoderated: true,
            showcase: this.showcase.value,
            isAllRussian: this.allRussian.value,
            isRegionalFederation: this.isRegionalFederation.value,
            contacts: {
                website: this.website.value,
                email: this.email.value,
                phone: `+${this.phoneCountrySelect.dialCode} ${this.phone.value}`,
                address: {
                    regionId: this.region.value === "" ? null : this.region.value,
                    cityId: this.city.value === "" ? null : this.city.value,
                },
                addressText: this.address.value,
            },
            additionalAddresses: this.additionalAddresses.map((additional) => {
                return {
                    groupId: this.group.id,
                    addressText: additional.address.value,
                    address: {
                        regionId: additional.regionPicker.value === "" ? null : additional.regionPicker.value,
                        cityId: additional.cityPicker.value === "" ? null : additional.cityPicker.value,
                    },
                };
            }),
        };
        const existedGroup = this.$store.getters[GroupModule.types.getters.GROUPS]
            .filter((item) => item.id !== this.group.id)
            .find((item) => item.fullName === group.fullName || item.shortName === group.shortName || item.code === group.code);
        if (existedGroup !== undefined) {
            return this.$notify({
                type: "error",
                duration: 3000,
                title: "Уже существует сообщество с такими параметрами",
            });
        }
        await this.$store.dispatch(GroupModule.types.actions.UPDATE_GROUP, { id: this.group.id, group });
        this.$notify({
            type: "success",
            title: "Сообщество успешно обновлено и отправлено на премодерацию",
        });
    }
    async mounted() {
        if (this.users.length === 0) {
            await this.$store.dispatch(UsersModule.types.actions.GET_USERS);
        }
        if (this.isUpdate) {
            this.setUpdateFormData();
        }
        else {
            // this.setTestFormData()
        }
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], GroupDialog.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean })
], GroupDialog.prototype, "isUpdate", void 0);
__decorate([
    Prop({ type: Object })
], GroupDialog.prototype, "group", void 0);
GroupDialog = __decorate([
    Component({
        components: {
            ApproveDialog,
            VueEditor,
        },
        directives: {
            mask,
        },
    })
], GroupDialog);
export default GroupDialog;
